
import 'splitpanes/dist/splitpanes.css'

import { CloudOutlined } from '@ant-design/icons-vue'
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { message } from 'ant-design-vue'
import { isEmpty, keyBy } from 'lodash-es'
import { isNil } from 'ramda'
import { Pane, Splitpanes } from 'splitpanes'
import { computed, defineComponent, onMounted, ref } from 'vue'

import useAnalytics, { exportAnalytics } from '@/components/composables/analytics'
import useMethods from '@/components/composables/methods'
import useSurvey from '@/components/composables/survey'
import AceTable from '@/components/method-ace/AceTable.vue'
import CainTable from '@/components/method-cain/CainTable.vue'
import { TABLE_EVENTS } from '@/components/method-common/Table'
import MarginalTable from '@/components/method-marginal/MarginalTable.vue'
import { DB_ENUM_VALUES } from '@/constants/database'
import { EVENTS } from '@/constants/emits'
import { MESSAGE } from '@/constants/message'
import { ROUTE_NAME } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { Variable } from '@/libs/bayes'
import { persistMock, persistSummaries } from '@/services/composition/survey'
import { useStore } from '@/store'
import { SurveyActionEnum } from '@/store/enums/actions/survey'
import { AllocationStateEnum, UserStateEnum } from '@/store/enums/states'
import { vuexActions } from '@/store/util'
import { ResponseSchema, SurveyExportFormatEnum, User } from '@/types'

import VariableTable from '../variable/VariableTable.vue'

const { ALLOCATION } = ModuleNames

export default defineComponent({
  components: {
    CloudOutlined,
    Pane,
    Splitpanes,
    AceTable,
    MarginalTable,
    VariableTable,
    CainTable
  },
  props: {
    workspaceId: { type: String, required: true },
    surveyId: { type: String, required: true }
  },
  setup(props) {
    const store = useStore()
    const isAnonymous = ref(true)

    const {
      cpt,
      cptSet,
      currentNetwork,
      currentSurvey,
      network,
      hasDiscreteScale,
      selectedVariable,
      persistResponses,
      persistedResponses,
      selectVariable
    } = useSurvey(store, true)

    const {
      isCain,
      isAceOriginal,
      isAceDst,
      isAceDstSimple,
      isAceDstFull,
      isMarginal
    } = useMethods(cpt)

    const userListContent = computed(() => store.state.user[UserStateEnum.USER_LIST]?.content)
    const userMap = computed(() => keyBy(store.state.user[UserStateEnum.USER_LIST]?.content, 'id'))
    const allocations = computed(
      () => store.state[ALLOCATION][AllocationStateEnum.ALLOCATION_LIST]?.content
    )

    const rows = computed(() => cpt.value?.elicitedRows || [])

    const { analyticsMap } = useAnalytics(network, allocations, persistedResponses, userMap, cptSet)

    const selectedVariableResponses = computed(() => {
      const variable = selectedVariable.value
      if (variable && rows.value?.length) {
        return analyticsMap.value[variable.id]?.aggResponses
      }
      return []
    })

    const selectedAnalytics = computed(() => {
      const variable = selectedVariable.value
      if (variable && rows.value?.length) {
        return analyticsMap.value[variable.id]
      }
      return []
    })

    const publishMock = async () => {
      if (currentNetwork?.value && currentSurvey.value?.id && cptSet.value) {
        await persistMock(currentNetwork.value.id, currentSurvey.value.id, cptSet.value)
      }
    }

    const publishAgg = async () => {
      if (currentNetwork?.value && currentSurvey.value?.id && cptSet.value) {
        await persistSummaries(
          analyticsMap.value,
          currentNetwork.value.id,
          currentSurvey.value.id,
          cptSet.value,
          !isNil(currentNetwork.value?.fsNetworkId),
          isAnonymous.value
        )
      }
    }

    onMounted(() => {
      if (network?.value) {
        selectedVariable.value = network?.value?.variables[0]
      }
    })

    const isOperating = ref(false)

    /**
     * Export CPT response for current survey
     * @param fileType - output file type
     **/
    const exportCPTResponse = (fileType: SurveyExportFormatEnum) => {
      if (props.surveyId && !isEmpty(network.value.variables)) {
        store.dispatch(vuexActions(ModuleNames.SURVEY, SurveyActionEnum.EXPORT_SURVEY), {
          surveyId: props.surveyId,
          format: fileType,
          userIds: userListContent.value.map((each: User) => each.id) as Array<User['id']>,
          variableIds: network.value.variables.map((each: Variable) => each.id)
        })
      }
    }

    const exportSurveyData = async () => {
      exportAnalytics(
        props.surveyId,
        network.value,
        allocations.value,
        userMap.value,
        cptSet.value,
        analyticsMap.value,
        isAnonymous.value,
        currentSurvey.value
      )
    }

    const onResponsesSave = async ({ responses }: { responses: Array<ResponseSchema> }) => {
      isOperating.value = true
      try {
        persistResponses(responses, true) // true for analytics
        message.success(MESSAGE.CPT_RESPONSES_SAVE_SUCCESS)
      } catch (err) {
        message.error(MESSAGE.CPT_RESPONSES_SAVE_FAIL)
        isOperating.value = false
        throw err
      }
      isOperating.value = false
    }

    return {
      isAnonymous,
      DB_ENUM_VALUES,
      EVENTS,
      TABLE_EVENTS,
      cpt,
      cptSet,
      currentSurvey,
      selectedVariable,
      selectVariable,
      hasDiscreteScale,
      isOperating,
      analyticsMap,
      network,
      rows,
      isCain,
      isAceDst,
      isAceOriginal,
      isAceDstSimple,
      isAceDstFull,
      isMarginal,
      persistedResponses,
      publishAgg,
      SurveyExportFormatEnum,
      ROUTE_NAME,
      publishMock,
      selectedAnalytics,
      selectedVariableResponses,
      userMap,
      onResponsesSave,
      exportCPTResponse,
      exportSurveyData
    }
  }
})
